import { JSXElementConstructor, Key, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import InputField from '../../motifComponents/InputField/InputField';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSourceOnNewChat,
  selectedSourceValues,
  setSideBarOpenClose
} from '../../../store/actions/commonActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { IStore } from '../../../store';
import {
  redInfoIcon,
  tooltip,
  uploadButtonDisabled,
  uploadButtonEnabled
} from '../../../commonImage';
import { getSourceTypeName, isValidURL } from '../../../utils/commonUtils';
import { MotifLabel } from '@ey-xd/motif-react';
import RadioButton from '../../motifComponents/radioButton/RadioButton';
import {
  clearChatHistory,
  setSelectedDocumentThread,
  selectedDataSourceSection,
  setAnySourceSelected,
  setLastSavedThreadID,
  setISActiveTab,
  setSurveyList,
  setChatClicked
} from '../../../store/actions/userChatActions';
import LABELS from '../../../ui-constants/en.json';
import { DataSourceTypes } from '../../../models/IDataSourceTypes';
import { setSelectedChatThread } from '../../../store/actions/userChatActions';
import SourceChangeModal from '../../openAIChat/SourceChangeModel/SourceChangeModal';
import { initializeSession } from '../../../services/session.service';
import path from 'path';
import LoadIndicator from '../../common/loadIndicator/LoadIndicator';
import OverflowTooltip from '../../common/OverflowTooltip';
import { internalSources } from '../../../label.constant';

interface SourceData {
  item: string;
}

interface GroupedDataSources {
  public: SourceData[];
  internal: SourceData[];
}

interface PublicDataProps {
  dataSources: GroupedDataSources;
  isLoading?: boolean;
}

const SourceSelection = (props: PublicDataProps) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const selectedSourceOnNewChat = useSelector(
    (state: IStore) => state.common.selectedSourceOnNewChat
  );
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const selectedDocumentThread = useSelector(
    (store: IStore) => store.userChat.selectedDocumentThread
  );
  const treadURLinputValue = useSelector(
    (state: IStore) => state?.userChat?.selectedChatThread?.dataSubSource
  );
  const activeTabValue = useSelector((store: IStore) => store.userChat.activeTabValue);
  const [isParentSelected, setIsParentSelected] = useState(selectedSourceOnNewChat);
  const threadEYIPSource: string =
    useSelector((state: IStore) => state.common.eyIPInterRadio) ?? '';
  const [eyIPInterRadio, setEYIPInternalRadio] = useState('');
  const threadCheckedBoxes: string[] =
    useSelector((state: IStore) => state.common.checkedCheckboxes) ?? [];
  const selURLinputValue = useSelector((state: IStore) => state.common.validURLinputValue);
  const currentDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<Array<string>>(threadCheckedBoxes);
  const [validURLinputValue, setValidURLinputValue] = useState<any>('');
  const [validURL, setValidURL] = useState<boolean>(true);
  const [showSourceChange, setShowSourceChange] = useState<any>('');
  const [onCheckid, setOnCheckId] = useState<any>('');
  const [isUploadURL, setIsUploadURl] = useState<any>(false);
  const isLoading = useSelector((state: any) => state.userChat.isLoading);
  const lastSavedThreadID = useSelector((state: IStore) => state?.userChat?.lastSavedThreadID) || 0;

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    if (!selectedChatThread?.threadId) {
      dispatch(selectSourceOnNewChat(''));
      if (
        pathname === '/' ||
        pathname === '/publicChatPage' ||
        (pathname === '/newChat' && threadEYIPSource !== 'Surveys')
      ) {
        setIsParentSelected('radio-public');
        dispatch(selectedDataSourceSection(DataSourceTypes.PublicData));
      } else if (
        pathname === '/sectorChatPage' ||
        pathname === '/surveyChatPage' ||
        pathname === '/kcChatPage' ||
        (pathname === '/newChat' && threadEYIPSource === 'Surveys')
      ) {
        setIsParentSelected('radio-internal');
        dispatch(selectedDataSourceSection(DataSourceTypes.EYIP));
      } else if (pathname === '/uploadPage') {
        setIsParentSelected('radio-uploadDocument');
        dispatch(selectedDataSourceSection(DataSourceTypes.BYOD));
      } else if (pathname === '/uploadURLPage') {
        setIsParentSelected('radio-uploadUrl');
        dispatch(selectedDataSourceSection(DataSourceTypes.UploadURL));
      }
    }
  }, [isSidebarOpen, selectedSourceOnNewChat]);

  useEffect(() => {
    if (pathname === '/') {
      setCheckedCheckboxes([]);
      setEYIPInternalRadio('');
      setValidURLinputValue('');
    }
  }, [pathname]);

  useEffect(() => {
    if (selectedChatThread?.threadId && lastSavedThreadID === 0) {
      setCheckedCheckboxes([]);
      setEYIPInternalRadio('');
      setValidURLinputValue('');
      setIsUploadURl(false);
    }
  }, [selectedChatThread]);

  useEffect(() => {
    if (selectedDocumentThread?.threadId) {
      setCheckedCheckboxes([]);
      setEYIPInternalRadio('');
      setValidURLinputValue(treadURLinputValue);
    }
  }, [selectedDocumentThread]);

  useEffect(() => {
    let currentThreadDataSource =
      activeTabValue === 'Chat history'
        ? selectedChatThread?.dataSource
        : selectedDocumentThread?.dataSource;
    if (currentThreadDataSource === DataSourceTypes.PublicData) setIsParentSelected('radio-public');
    else if (currentThreadDataSource === DataSourceTypes.EYIP)
      setIsParentSelected('radio-internal');
    else if (currentThreadDataSource === DataSourceTypes.BYOD)
      setIsParentSelected('radio-uploadDocument');
    else if (currentThreadDataSource === DataSourceTypes.Experts)
      setIsParentSelected('radio-findEyExpert');
    else if (currentThreadDataSource === DataSourceTypes.UploadURL)
      setIsParentSelected('radio-uploadUrl');
  }, [selectedChatThread, selectedDocumentThread]);

  const selectParentRadio = (e: any) => {
    const selectedId = (e.target as HTMLInputElement).id;
    setIsParentSelected(selectedId);
    if ((e.target as HTMLInputElement)?.id === 'radio-internal') {
      if (
        pathname === '/publicChatPage' ||
        pathname === '/uploadPage' ||
        pathname === '/exploreData' ||
        pathname === '/expertChatPage'
      ) {
        navigate('/');
      }
      setCheckedCheckboxes([]);
      setValidURLinputValue('');
      setIsUploadURl(false);
      dispatch(selectedDataSourceSection(DataSourceTypes.EYIP));
    } else if ((e.target as HTMLInputElement)?.id === 'radio-uploadDocument') {
      dispatch(selectedDataSourceSection(DataSourceTypes.BYOD));
      setValidURLinputValue('');
      setCheckedCheckboxes([]);
      setEYIPInternalRadio('');
      setIsUploadURl(false);
      navigate('/uploadPage');
    } else if ((e.target as HTMLInputElement)?.id === 'radio-uploadUrl') {
      dispatch(selectedDataSourceSection(DataSourceTypes.UploadURL));
      setCheckedCheckboxes([]);
      setEYIPInternalRadio('');
      setValidURLinputValue('');
      navigate('/');
    } else if ((e.target as HTMLInputElement)?.id === 'radio-findEyExpert') {
      dispatch(selectedDataSourceSection(DataSourceTypes.Experts));
      setCheckedCheckboxes([]);
      setEYIPInternalRadio('');
      setValidURLinputValue('');
      setIsUploadURl(false);
      navigate('/expertChatPage');
    } else {
      if (pathname === '/uploadPage') {
        navigate('/');
      }
      setValidURLinputValue('');
      setEYIPInternalRadio('');
      if ((e.target as HTMLInputElement)?.id === 'radio-public') {
        navigate('/');
        dispatch(selectedDataSourceSection(DataSourceTypes.PublicData));
      }
    }
    setIsUploadURl(false);
    selectedChatThread.isSelected = false;
    dispatch(setLastSavedThreadID(0));
    dispatch(setSelectedChatThread({}));
    selectedDocumentThread.isSelected = false;
    dispatch(setSelectedDocumentThread({}));
  };

  const handleRadioChangeInternal = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setEYIPInternalRadio((e.target as HTMLInputElement)?.id);
    setIsUploadURl(false);
    dispatch(clearChatHistory());
    selectedChatThread.isSelected = false;
    dispatch(setLastSavedThreadID(0));
    dispatch(setSelectedChatThread({}));
    selectedDocumentThread.isSelected = false;
    dispatch(setSelectedDocumentThread({}));
    if ((e.target as HTMLInputElement)?.id === LABELS.SECTOR_INSIGHTS_TILE) {
      navigate('/sectorChatPage');
    } else if ((e.target as HTMLInputElement)?.id === internalSources[2]) {
      navigate('/kcChatPage');
    } else {
      navigate('/newChat');
    }
  };

  const handleCheckboxChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const ids = (e.target as HTMLInputElement)?.id;
    setIsUploadURl(false);
    if ((e.target as HTMLInputElement)?.checked) {
      if ((e.target as HTMLInputElement)?.checked && ids === 'Market reports') {
        setCheckedCheckboxes([...checkedCheckboxes, ids].filter((it) => it === 'Market reports'));
        navigate('/marketReports');
      } else {
        setCheckedCheckboxes([...checkedCheckboxes, ids].filter((it) => it !== 'Market reports'));
        navigate('/');
      }
    } else {
      setCheckedCheckboxes(checkedCheckboxes.filter((id) => id !== ids));
      navigate('/');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newURL = event.target.value;
    setValidURLinputValue(newURL);
    setValidURL(isValidURL(newURL));
  };

  const urlChange = (e: any) => {
    setOnCheckId(e);
    if (
      selURLinputValue === '' ||
      selURLinputValue === undefined ||
      Object.keys(selectedChatThread).length === 0
    ) {
      handleInputChange(e);
    } else {
      showSourceChangeModal(e);
      setIsUploadURl(true);
    }
  };

  useEffect(() => {
    setCheckedCheckboxes(threadCheckedBoxes);
  }, [threadCheckedBoxes]);

  useEffect(() => {
    if (threadEYIPSource) setEYIPInternalRadio(threadEYIPSource);
    else {
      if (pathname == '/') setEYIPInternalRadio('');
    }
  }, [threadEYIPSource]);

  useEffect(() => {
    dispatch(setAnySourceSelected(true));
    dispatch(
      selectedSourceValues({
        eyIPInterRadio: eyIPInterRadio,
        checkedCheckboxes: checkedCheckboxes,
        validURLinputValue: validURLinputValue
      })
    );
    if (selectedChatThread.dataSource === 4 && selectedChatThread.dataSubSource) {
      setValidURLinputValue(selectedChatThread.dataSubSource);
      setValidURL(true);
    }
    if (selectedChatThread.threadId && selectedChatThread.dataSource !== 4)
      setValidURLinputValue('');
  }, [eyIPInterRadio, checkedCheckboxes, validURLinputValue]);

  useEffect(() => {
    if (currentDataSourceSection === 3) dispatch(setISActiveTab('Document history'));
    else dispatch(setISActiveTab('Chat history'));
  }, [currentDataSourceSection]);

  // useEffect(() => {
  //   if (checkedCheckboxes?.length > 0 || eyIPInterRadio || validURLinputValue)
  //     dispatch(setAnySourceSelected(true));
  //   if (selectedChatThread.dataSource === 4 && selectedChatThread.dataSubSource) {
  //     setValidURLinputValue(selectedChatThread.dataSubSource);
  //     setValidURL(true);
  //   }
  //   if (selectedChatThread.threadId && selectedChatThread.dataSource !== 4) setValidURLinputValue('');
  // }, [eyIPInterRadio, checkedCheckboxes, validURLinputValue, selURLinputValue]);

  const showSourceChangeModal = (e: any) => {
    setShowSourceChange(e);
  };
  const resetUploadURLFilter = () => {
    setIsParentSelected('radio-uploadUrl');
    dispatch(selectedDataSourceSection(DataSourceTypes.UploadURL));
    dispatch(
      selectedSourceValues({
        eyIPInterRadio: '',
        checkedCheckboxes: [],
        validURLinputValue: ''
      })
    );
    setEYIPInternalRadio('');
    setCheckedCheckboxes([]);
    setValidURLinputValue('');
    setShowSourceChange('');
    selectedChatThread.isSelected = false;
    dispatch(setSelectedChatThread({}));
    selectedDocumentThread.isSelected = false;
    dispatch(setSelectedDocumentThread({}));
    dispatch(clearChatHistory());
  };
  const onContinue = () => {
    if (threadEYIPSource !== '') {
      handleRadioChangeInternal(onCheckid);
      setShowSourceChange('');
      if (
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-public' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-uploadDocument' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-uploadUrl' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-findEyExpert'
      ) {
        selectParentRadio(showSourceChange);
      }
      selectedChatThread.isSelected = false;
      dispatch(setSelectedChatThread({}));
      selectedDocumentThread.isSelected = false;
      dispatch(setSelectedDocumentThread({}));
      dispatch(clearChatHistory());
      dispatch(setSurveyList([]));
    } else if (checkedCheckboxes.length > 0) {
      const ids = (onCheckid.target as HTMLInputElement)?.id;
      if (ids === 'Market reports') {
        setCheckedCheckboxes(checkedCheckboxes.filter((it) => it !== 'Market reports'));
        navigate('/marketReports');
      } else {
        if ((onCheckid.target as HTMLInputElement)?.checked) {
          setCheckedCheckboxes(checkedCheckboxes.filter((id) => id !== ids));
          navigate('/');
        } else {
          setCheckedCheckboxes([...checkedCheckboxes, ids].filter((it) => it !== 'Market reports'));
          navigate('/');
        }
      }

      setShowSourceChange('');
      if (
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-internal' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-uploadDocument' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-uploadUrl' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-findEyExpert'
      ) {
        selectParentRadio(showSourceChange);
      }
      selectedChatThread.isSelected = false;
      dispatch(setSelectedChatThread({}));
      selectedDocumentThread.isSelected = false;
      dispatch(setSelectedDocumentThread({}));
      dispatch(clearChatHistory());
    } else if (selectedDocumentThread.isSelected === true) {
      setShowSourceChange('');
      if (
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-internal' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-public' ||
        (showSourceChange.target as HTMLInputElement)?.id === 'radio-findEyExpert'
      ) {
        selectParentRadio(showSourceChange);
      }
      selectedChatThread.isSelected = false;
      dispatch(setSelectedChatThread({}));
      selectedDocumentThread.isSelected = false;
      dispatch(setSelectedDocumentThread({}));
      dispatch(clearChatHistory());
    } else if (
      checkedCheckboxes.length === 0 &&
      threadEYIPSource === '' &&
      validURLinputValue &&
      isUploadURL
    ) {
      resetUploadURLFilter();
    } else {
      setShowSourceChange('');
      selectParentRadio(showSourceChange);
      selectedChatThread.isSelected = false;
      dispatch(setSelectedChatThread({}));
      selectedDocumentThread.isSelected = false;
      dispatch(setSelectedDocumentThread({}));
      dispatch(clearChatHistory());
    }
  };

  const onClose = () => {
    setShowSourceChange('');
    setIsUploadURl(false);
  };

  const onRadioChange = (e: any) => {
    dispatch(setChatClicked(false));
    if (
      Object.keys(selectedChatThread).length === 0 &&
      Object.keys(selectedDocumentThread).length === 0
    ) {
      selectParentRadio(e);
    } else if (
      Object.keys(selectedChatThread).length !== 0 &&
      Object.keys(selectedDocumentThread).length === 0
    ) {
      showSourceChangeModal(e);
    } else if (
      Object.keys(selectedChatThread).length === 0 &&
      Object.keys(selectedDocumentThread).length !== 0
    ) {
      showSourceChangeModal(e);
    }
  };

  const anySourceSelected = useSelector((state: IStore) => state?.userChat?.anySourceSelected);
  const [isSourceSelected, setSourceSelected] = useState<any>(!anySourceSelected);
  useEffect(() => {
    setSourceSelected(anySourceSelected);
  }, [anySourceSelected, isSourceSelected]);

  const onCheckBoxChange = (e: any) => {
    setOnCheckId(e);
    setIsUploadURl(false);
    if (Object.keys(selectedChatThread).length === 0) {
      handleCheckboxChange(e);
    } else {
      showSourceChangeModal(e);
    }
  };

  const onIntrRadioChange = (e: any) => {
    dispatch(setChatClicked(false));
    setOnCheckId(e);
    setIsUploadURl(false);
    if (threadEYIPSource === '' && Object.keys(selectedChatThread).length === 0) {
      handleRadioChangeInternal(e);
    } else if (threadEYIPSource !== '' && Object.keys(selectedChatThread).length === 0) {
      handleRadioChangeInternal(e);
    } else {
      showSourceChangeModal(e);
    }
  };

  if (Object.values(props.dataSources).every((sourceData) => sourceData?.length === 0)) {
    return !isLoading ? (
      <div className='load'>
        <LoadIndicator />
      </div>
    ) : (
      <></>
    );
  }
  const internalEYP: any = props.dataSources?.internal || [];
  const isEYP = internalEYP.includes('EY-P Strategy projects');

  const publicDataTooltip = (
    <>
      <span className='tooltip-info-header'>Embryonic</span>
      <div className='tooltip-info-spacer'></div>
      <span className='tooltip-info-description'>
        Harmonized dataset comprised of S&P Capital IQ, PitchBook, CB Insights, Crunchbase,
        Refinitiv, BioMedtracker.
      </span>
      <br />
      <br />
      <span className='tooltip-info-header'>Open AI</span>
      <div className='tooltip-info-spacer'></div>
      <span className='tooltip-info-description'>GPT-4o with training data upto Oct 2023.</span>
      <br />
      <br />
      <span className='tooltip-info-header'>Market Reports</span>
      <div className='tooltip-info-spacer'></div>
      <span className='tooltip-info-description'>
        Industry reports spanning 27+ sectors and 60+ geographies, featuring market research from
        IBIS World and Global Market Model (GMM).
      </span>
    </>
  );

  const EYIPTooltip = (
    <>
      {isEYP && (
        <>
          <span className='tooltip-info-header'>EY-P Strategy projects</span>
          <div className='tooltip-info-spacer'></div>
          <span className='tooltip-info-description'>
            Repository of sanitized insights gathered from past Strategy engagements to draw
            sector/market trends.
          </span>
          <br />
          <br />
        </>
      )}
      <span className='tooltip-info-header'>Surveys</span>
      <div className='tooltip-info-spacer'></div>
      <span className='tooltip-info-description'>
        Repository of EY surveys providing access to the collective insights to support new
        opportunities.
      </span>
      <br />
      <br />
      <span className='tooltip-info-header'>Discover: Sector Insights</span>
      <div className='tooltip-info-spacer'></div>
      <span className='tooltip-info-description'>
        Repository of SaT tagged Discover documents focused on sector insights and point-of-views.
      </span>
    </>
  );

  const EYExpertTooltip = (
    <>
      <span className='tooltip-info-header'>Find EY Expert</span>
      <div className='tooltip-info-spacer'></div>
      <span className='tooltip-info-description'>
        Collective experiences from past Strategy engagements enabling you to connect with
        colleagues with relevant expertise.
      </span>
    </>
  );

  const getSourceTypetooltip = (sourceType: string) => {
    switch (sourceType) {
      case 'public':
        return publicDataTooltip;

      case 'internal':
        return EYIPTooltip;

      case 'findEyExpert':
        return EYExpertTooltip;

      default:
        return publicDataTooltip;
    }
  };

  const getSourceTypetooltipClass = (sourceType: string) => {
    switch (sourceType) {
      case 'public':
        return 'public-tooltip-placement';

      case 'internal':
        return 'eyip-tooltip-placement';

      case 'findEyExpert':
        return 'eyexpert-tooltip-placement';

      default:
        return 'public-tooltip-placement';
    }
  };
  const handleCheckboxDisable = (item: any) => {
    if (checkedCheckboxes.includes('Market reports')) {
      return item === 'Embryonic' || item === 'Open AI';
    } else if (checkedCheckboxes.includes('Embryonic') || checkedCheckboxes.includes('Open AI')) {
      return item === 'Market reports';
    } else if (checkedCheckboxes.includes('Embryonic') && checkedCheckboxes.includes('Open AI')) {
      return item === 'Market reports';
    }
  };

  return (
    <StyledPublicData
      isSourceSelected={isSourceSelected}
      checkvalidURl={validURL}
      textLength={validURLinputValue?.length}>
      <label htmlFor='sidebar' className='select-source'>
        {'Select source(s)'}
      </label>
      {Object.entries(props.dataSources).map(([sourceType, sourceData], index) => {
        return (
          <>
            <div className={`flex-container`} key={`source-selection-${index}`}>
              <div
                className={`option ${isParentSelected === `radio-${sourceType}` ? `triangle triangle-${sourceType}` : ''}`}>
                <RadioButton
                  type='radio'
                  id={`radio-${sourceType}`}
                  name='sourceType'
                  className='radio-header'
                  onChange={onRadioChange}
                  disabled={isLoading}
                  checked={isParentSelected === `radio-${sourceType}`}
                />
                <label htmlFor={`radio-${sourceType}`} className='fontWeight'>
                  {getSourceTypeName(sourceType)}
                </label>
              </div>
              {sourceType !== 'uploadDocument' && (
                <OverflowTooltip
                  className='tooltip-container'
                  tooltipContent={getSourceTypetooltip(sourceType)}
                  tooltipClassName={getSourceTypetooltipClass(sourceType)}
                  isSourceTooltip={true}
                  placement='bottom-start'
                  trigger='click'>
                  <img className='tooltip-image' src={tooltip} alt='tooltip'></img>
                </OverflowTooltip>
              )}
            </div>
            <div className='checkboxes'>
              {sourceData?.map(
                (
                  item:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | null
                    | undefined,
                  index: Key | null | undefined
                ) => (
                  <div key={index} className='single-checkbox'>
                    {sourceType === 'internal' ? (
                      <RadioButton
                        type='radio'
                        id={`${item}`}
                        name={`radioGroup`}
                        onChange={onIntrRadioChange}
                        disabled={
                          isLoading ||
                          isParentSelected !== `radio-${sourceType}` ||
                          item === 'Transcripts'
                        }
                        checked={eyIPInterRadio === item}
                        className='motif-radio-button button-position'
                      />
                    ) : (
                      <>
                        <input
                          type={'checkbox'}
                          id={`${item}`}
                          name={`item${item}`}
                          onChange={onCheckBoxChange}
                          disabled={
                            isParentSelected !== `radio-${sourceType}` ||
                            isLoading ||
                            handleCheckboxDisable(item)
                          }
                          checked={checkedCheckboxes.includes(String(item))}
                        />
                      </>
                    )}
                    <label className='select-source checkbox-label' htmlFor={`${item}`}>
                      {item === 'Market reports' ? 'Market Reports' : item}
                    </label>
                  </div>
                )
              )}
            </div>

            {/* {sourceType === 'uploadUrl' && isParentSelected === `radio-${'uploadUrl'}` &&(
              <div className='inputTextField'>
                <InputField
                  type='text'
                  inputValue={validURLinputValue}
                  handleChange={urlChange}
                  placeholder='https://www.ey.com/'
                  className={
                    !validURL && validURLinputValue?.length > 0
                      ? 'motif-input-components-withBorder'
                      : 'motif-input-components-withoutBorder'
                  }
                  disabled={isParentSelected !== `radio-${sourceType}`}
                  hideClearButton={true}
                  maxLength={1000}
                />

                {validURL && validURLinputValue?.length > 0 && !(pathname === '/uploadURLPage') && (
                  <>
                    <MotifLabel className='error warning' hidden={true}>
                      Valid URL
                    </MotifLabel>
                  </>
                )}

                {!validURL && validURLinputValue?.length > 0 && (
                  <>
                    <img src={redInfoIcon} className='infoIcon' />
                    <MotifLabel className='error warning' hidden={validURL}>
                      Invalid URL
                    </MotifLabel>
                  </>
                )}
              </div>
            )} */}
          </>
        );
      })}
      {showSourceChange && (
        <SourceChangeModal
          show={showSourceChange !== '' ? true : false}
          onClose={() => onClose()}
          onContinue={() => onContinue()}
          reportId={''}
          souceChange={true}
          isUploadURL={isUploadURL}
        />
      )}
    </StyledPublicData>
  );
};

const StyledPublicData = styled.div<{
  isSourceSelected: boolean;
  checkvalidURl: boolean;
  textLength: number;
}>`
  margin-left: -5px;
  .internal-option {
    margin: 20px 0 10px 0;
  }

  .single-checkbox {
    padding: 5px 0;
  }
  .single-checkbox-border {
    padding: 5px 0px 10px;
    border-bottom: 1px solid #d7d7dc;
  }
  .single-checkbox-top {
    padding: 8px 0px 5px;
  }
  .fontWeight {
    font-weight: 700;
    font-size: 14px;
    position: relative;
    top: -2px;
    color: black;
  }
  .fontWeightUpload {
    font-weight: 700;
    font-size: 14px;
  }
  .checbox-label {
    position: relative;
    bottom: 3px;
    padding-left: 4px;
  }
  .file-type {
    display: none;
  }
  .file-upload-icon {
    padding: 10px 0px 0 30px;
  }
  .motif-input-components-withBorder {
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #fff;
    margin: 10px;
    height: 45px;
    width: 90%;
    color: #9897a6;
    border: 2px solid #ea011d;
  }

  .motif-input-components-withoutBorder {
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #fff;
    margin: 10px;
    height: 45px;
    width: 90%;
    color: #9897a6;
  }
  .inputTextField {
    position: relative;
    .error {
      position: relative;
      top: -13px;
      font-size: 12px;
      left: 12px;
    }
  }
  .infoIcon {
    position: absolute;
    top: 14px;
    right: 20px;
  }
  .warning {
    color: #fcf5f3;
    font-size: 14px;
    margin-left: 10px;
  }
  .motif-radio-button .motif-radio-button-border {
    background-color: #fff;
    fill: #fff;
  }
  .motif-radio-button-custom {
    border: 1px solid;
  }

  .input:checked + .motif-radio-button-custom .motif-radio-button-bullet {
    background-color: #fff;
    border: 1px solid #fff;
  }

  .motif-radio-button .motif-radio-button-bullet {
    fill: rgba(46, 46, 56, 1);
    width: 10px !important; /* Adjust this value to change the bullet size */
    height: 10px !important;
  }
  .motif-radio-button svg {
    width: 13.3px;
    height: 13.3px;
  }

  .motif-radio-button-input:disabled + .motif-radio-button-custom {
    background-color: #ccc;
    border: 2px;
  }

  .triangle {
    position: relative;
  }

  .triangle::before {
    content: '';
    position: absolute;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: ${(props) =>
      props.isSourceSelected ? '18px solid rgba(243, 243, 245, 1)' : '17px solid red'};
    // left: -32%;
    z-index: 99;
  }

  .triangle-public::before {
    left: -32% !important;
  }

  .triangle-internal::before {
    left: -29% !important;
  }

  .triangle-uploadDocument::before {
    left: -17% !important;
  }

  .triangle-uploadUrl::before {
    left: -29% !important;
  }

  .triangle-findEyExpert::before {
    left: -29% !important;
  }

  .button-position {
    position: relative;
    top: 2px;
  }

  .motif-input-component .motif-input:focus-visible:not(:disabled) {
    outline: none;
    border: ${(props) =>
      props.checkvalidURl && props.textLength > 0 ? '1px solid lightgreen' : ''};
  }

  .flex-container {
    display: flex;
    align-items: center; /* This will vertically center the items if they have different heights */
  }

  .tooltip-image {
    transform: translateY(5px);
  }

  .tooltip-container {
    cursor: pointer;
  }
`;

export default SourceSelection;
